<template>
  <div  class="px-15px px-lg-25px">
    <div class="aiz-titlebar mt-2 mb-4">
      <div class="row align-items-center">
        <div class="col-md-6">
          <b class="h4">{{ $t('dui-hua') }}</b>
        </div>
      </div>
    </div>

    <div class="card">
       <div class="row">
         
          <div class="col-md-12 right-box ">
            <div class="top-info">
              <div class="time">
                <Divider  :style="{  borderColor: '#ccc', padding: '0 16px' }">{{getTime()}}</Divider>
              </div>
              <div class="message-box" id="messageBox">
                <div class="message" v-for="(item, i) in messageList" :key="i" :class="item.ToUId == customId ? 'right' : 'left'">
                  <div class="header" v-if="item.ToUId != customId">
                    <img :src="item.ToUser.Photo ? `/upload/${item.ToUser.Photo}` : headerUrl" alt="">
                  </div>
                  <div class="right-info">
                    <div class="name" >{{item.FormUser.FullName}}</div>
                    <div class="content">{{item.Content}}</div>
                    <div class="status" v-if="item.IsRead == 1">{{ $t('yi-du') }}</div>
                  </div>
                  <div class="header"  v-if="item.ToUId == customId">
                    <img :src="userInfo.Photo ? `/upload/${userInfo.Photo}` : headerUrl" alt="">
                  </div>
                </div>
              </div>
            </div>

            <form class="pt-4 message-form" >
              <div class="form-group">
                <textarea class="form-control height-auto" rows="4" name="message" :placeholder="$t('qing-shu-ru-nin-de-hui-fu')" required></textarea>
              </div>
              <div class="form-group mb-0 text-right">
                <button type="button" class="btn btn-primary">{{ $t('fa-song') }}</button>
              </div>
            </form>
          </div>
        </div>
    </div>

  
  </div>
</template>
<script>
import pageBar from '@/components/page'
import moment from 'moment'
import { Divider  } from 'vant'
import  { chatList } from '@/api/index'
import { initWebSocket } from '@/utils/common'
import { getToken  } from '@/utils/auth'
export default {
  components: {
    pageBar,
    Divider 
  },
  data() {
    return {
      headerUrl: require('../../../assets/imgs/avatar-place.png'),
      messageIndex: 0,
      usersList: [],
      messageList: [],
      customId: '',
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  mounted() {
    this.customId = this.$route.query.id
    this.initSocket()
    this.initHistory(this.customId)
  },
  methods: {
    initHistory(id) {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 20)
      form.append('to_uid', id)
      chatList(form).then(res => {
        this.messageList = res.data.Items.reverse()
        setTimeout(() => {
          document.querySelector('#messageBox').scrollTo({top: 10000})
        }, 300)
      })
    },
    async initSocket(){ //初始化weosocket
      let client = await initWebSocket(this.websocketonmessage, this.initSocket)
      this.webSocket = client
    },
    websocketonmessage(e) {
      let redata = {}
      if (e.data.indexOf('Welcome') > -1) {
				this.webSocket.send(JSON.stringify({
          action: 'login',
          to: '',
          message: getToken()
        }))
			}
      try {
        redata = JSON.parse(e.data)
        this.setListData(redata)
      }
      catch(e) {
      }
    },
    setListData(data) {
      this.messageList.push(data)
      document.querySelector('#messageBox').scrollTo({top: 10000})
    },
    sendMsg() {
      if (!this.content) {
        this.$toast.fail( this.$t('xiao-xi-bu-neng-wei-kong') )
        return
      }
      let content = {
        action: 'publish',
        to: this.customId + '',
        message: this.content
      }
      this.webSocket.send(JSON.stringify(content))
      this.messageList.push({
        ToUId: this.customId,
        FromUId: this.userInfo.UserAccount.UId,
        CreatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        FormUser: {
          FullName: this.userInfo.FullName,
        },
        Content: this.content
      })
      this.content = ''
      
    },
    getTime() {
      if (this.messageList.length > 0) {
        return this.messageList[0].CreatedAt
      } else {
        return moment().format('YYYY-MM-DD HH:mm:ss')
      }
    },
  }
}
</script>
<style scoped lang="less">
  .list-group {
    .item {
      border-bottom: 1px solid #ccc;
      padding: 12px 0 10px;
      margin-left: 0 !important;
      &:last-child {
        border-bottom: none;
      }
      &.active {
        background: #fff;
      }
    }
  }
  .left-box {
    height: 100%;
    border-right: 1px solid #ccc;
    overflow: auto;
    background: #f7f7f7;
    padding: 0;
  }
  .right-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
    .top-info {
      flex: 1;
      overflow: auto;
    }
    .message-form {
      background: #fff;
    }
    .message-box {
      width: 100%;
      .message {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        &.right {
          justify-content: flex-end;
          .name, .status {
            text-align: right;
            padding-left: 0;
            padding-right: 5px;
          }
          .right-info {
            margin-left: 0;
            margin-right: 10px;
            .content {
              background: #0259e1;
              color: #fff;
            }
          }
        }
        .header {
          width: 40px;
          height: 40px;
          margin-top: 20px;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 40px;
            height: 40px;
          }
        }
        .right-info {
          margin-left: 10px;
          .name {
            padding-left: 5px;
            font-size: 12px;
          }
          .status {
            padding-left: 5px;
            font-size: 12px;
          }
          .content {
            margin: 3px 0 6px;
            padding: 10px 16px;
            border-radius: 24px;
            font-size: 14px;
            color: #000;
            line-height: 22px;
            background: #f3f3f3;
          }
        }
      }
    }
  }
  .card {
    height: calc(100vh - 220px);
    overflow: hidden;
    .row {
      height: 100%;
      overflow: hidden;
      margin-left: 0;
      margin-right: 0;
    }
  }
</style>